import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import './Layout.scss';

const Layout = () => {
    
    return (
        <main className="App">
                <Header></Header>
                <div className="outlet"><Outlet/></div>
        </main>
            
        
    )
}

export default Layout;