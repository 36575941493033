import { Fragment, useState } from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import ImageSlider from '../../../UI/ImageSlider/ImageSlider';
import './ProjectsSection.scss';

const spartaLogo = require('../../../assets/sparta_logo.jpg');
const nofarLogo = 'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/nofar-logo.png';
const doReelLogo = 'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/imai-logo.png';
const projectShowcase = require('../../../assets/showcase.png');

const test = [
    {
        src: spartaLogo, title: 'מערכת CRM לניהול דירות',
        text: 'בניית מערכת ניהול לחברת ספרטה שיווק מתחמים, המערכת מכילה מערכת יוזרים, ניהול דירות, ניהול לידים ונוטיפיקציות',
        images: [
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/apt_overview.png',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/inside_apt.png',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/sparta_form_example.png'
        ],
        metaText: 'המערכת נבנתה בעקבות הצורך של חברת ספרטה לנהל דירות ולהתריע לסוכנים בעת הצורך. למערכת יש מספר מאפיינים, מערכת יוזרים לשליטת המידע לכל סוכן, מערכת לידים של דירות, מערכת ניהול דירות ופילטור מתקדם. הכל נעשה בשיתוף ומתוך הבנת צרכי הלקוח.'
    }
];

const nofarWebsite = [
    {
        src: nofarLogo, title: 'אתר תדמית',
        text: 'בניית אתר תדמית בסיסי, המכיל את דף הבית עם תמונות מתחלפות, אודות, דף פרוייקטים ודף השארת פרטים',
        images: [
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/nofar-homepage.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/nofar-about.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/nofar-projects.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/nofar-project.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/nofar-contact.jpg',
        ],
        metaText: 'המערכת נבנתה בעקבות הצורך של נופר להציג מידע אודות עבודותיה והשירות שהיא מספקת. למערכת יש מספר מאפיינים, השארת פרטים, גלריית תמונות לכל פרוייקט, ואפקטים לתמונות. הכל נעשה בשיתוף ומתוך הבנת צרכי הלקוח.'
    }
];

const doReelWebsite = [
    {
        src: doReelLogo, title: 'DoReel ugc application',
        text: 'הוא אפליקציה חדשנית המאפשרת למשתמשים ליצור ולשתף סרטונים קצרים בקלות ובמהירות. האפליקציה מציעה מגוון רחב של כלים לעריכה ואפקטים מיוחדים, המעשירים את חווית היצירה והשיתוף.',
        images: [
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/creator-chat.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/creator-earnings.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/creator-offers.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/creator-profile.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/creator-settings.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/creator-todo-page.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/customer-admin.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/customer-chat.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/customer-homepage.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/customer-initial-flow.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/customer-shipment.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/customer-stepps.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/customer-task.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/customer-tasks.jpg',
            'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/mobile-login.jpg',
        ],
        metaText: "DoReel היא אפליקציה חדשנית ליצירת ושיתוף סרטונים קצרים, המציעה חווית משתמש עשירה ואינטואיטיבית בשפות רבות. האפליקציה מאפשרת למשתמשים ליהנות מממשק דו-לשוני, ולהגיע לקהל רחב יותר תוך שמירה על נגישות מקסימלית. בנוסף, DoReel משלבת מערכת תשלומים מובנית, מה שמאפשר ליוצרי תוכן להרוויח מיצירותיהם בקלות ובבטחה. פיצ'ר הצ'אט המתקדם מעניק פלטפורמה לתקשורת ישירה ואינטראקציה בין המשתמשים, מגביר את האינטראקציה ומעמיק את החוויה הקהילתית בתוך האפליקציה. DoReel מביאה עידן חדש של יצירת וידאו דיגיטלי, עם דגש על גמישות, נגישות וחדשנות."
    }
];


const ProjectsSection = ({btnText, title}) => {
    const [isProjectToggled, setIsProjectToggled] = useState(false);
    const [currProject, setCurrProject] = useState();
    const [imgCard, setImgCard] = useState('');
    const { width } = useWindowDimensions();
    
    // Combine the two arrays for display
    const combinedProjects = [...test, ...nofarWebsite, ...doReelWebsite];

    const toggleProject = (position) => {
        setIsProjectToggled(oldValue => !oldValue);
        setCurrProject(combinedProjects[position]);
    };

    const openImg = (imgUrl) => {
        setImgCard(imgUrl);
    };

    const clearImg = () => {
        setImgCard(undefined);
    };

    return (
        <Fragment>
            {
                imgCard && width > 430 &&
                <div className='image_card'>
                    <div className='close_opened_img' onClick={clearImg}>X</div>
                    <img alt='opened_img' className='opened_img' src={imgCard}/>
                </div>
            }
            {!isProjectToggled && <div className='projects_section_container'>
                {title && <div className='title'>{title}</div>}
                {combinedProjects.map((project, i) => {
                    return <div className='project_container' key={project.title + i}>
                        <div className='showcase_container'>
                            <img className='showcase' alt='showcase' src={projectShowcase}/>
                            <div className='showcase_content'>
                                <img className='showcase_content_img' alt='showcase_content' src={project.src}/>
                            </div>
                        </div>
                        <div className='content_container'>
                            <div className='project_title'>{project.title}</div>
                            <div className='project_content'>{project.text}</div>
                        </div>
                        <button className='project_btn' onClick={() => toggleProject(i)}>מעבר לפרוייקט</button>
                    </div>;
                })}
            </div>}
            {
                isProjectToggled && 
                <div className='project_card'>
                    <div className='project_close' onClick={() => toggleProject()}>X</div>
                    <div className='project_title'>{currProject.title}</div>
                    <div className='project_text'>{currProject.metaText}</div>
                    <ImageSlider slides={currProject?.images} openImg={openImg}/>
                </div>
            }
        </Fragment>
    );
};

export default ProjectsSection;
