import { Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import './ServicesSection.scss';

const applicationImage = require('../../../assets/application.png');

const ecommerceImage = require('../../../assets/ecommerce.png');

const websitesImage = require('../../../assets/services_websites.png');

const ServicesSection = ({btnText, title}) => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <div className='services_section_container'>
                {title && <div className='service_main_title'>{title}</div>}
                <div className='project_types'>
                    <div className='ecommerce item'>
                        <img src={ecommerceImage} alt='ecommerce' className='service_img'/>
                        <h5 className='service_title'>איקומרס</h5>
                        <p className='service_text'>
                        <br/>כיום כל עסק המוכר מוצרים זקוק
                        <br/>לאתר בשביל לקדם את השירותים
                        <br/>והמוצרים שלהם ברחבי העולם. וואפי
                        <br/>מבינים את הצרכים שלכם ומספקים
                        <br/>פתרון commerce-E לעסק. 
                        </p>
                    </div>
                    <div className='business_web item'>
                    <img src={websitesImage} alt='website' className='service_img'/>
                        <h5 className='service_title'>אתרי תוכן</h5>
                        <p className='service_text'>
                        בעל עסק? כיום אתר תוכן תדמיתי<br/> הוא כמעט בגדר חובה.
                        </p>
                    </div>
                    <div className='applications item'>
                        <img src={applicationImage} alt='application' className='app_img service_img'/>
                        <h5 className='service_title app_title'>אפליקציה</h5>
                        <p className='service_text app_text'>
                        <br/>כהאפליקציה נכתבת בסטנדרט הכי
                        <br/>גבוה שקיים היום בשוק עם
                        <br/>הטכנולוגיות הכי חדישות, כאשר
                        <br/>המוצר מותאם לאנדרואיד ולאפל.
                        </p>
                    </div> 
                </div>
                
                <button className='continue_btn' onClick={e => navigate('/services')}>{btnText}</button>
            </div>
            
        </Fragment>
        
    )
}

export default ServicesSection