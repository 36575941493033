import { Fragment } from 'react';
import './ContactSection.scss';
import useForm from '../../../hooks/useForm';


const ContactSection = ({btnText, title, text, className, src, isSectionImg, textColor}) => {
    const { formData, setFormData, handleSubmit } = useForm();

    return (
        <Fragment>
            <div className={className}>
                {isSectionImg && <img alt='contact_section_image' src={src} className='contact_section_img'/>}
                {title && <div className='contact_title'>{title}</div>}
                <div className='contact_text'>{text}</div>
                <form className="contact_form">
                    <div className="form-item">
                        <input
                            style={{color: textColor ? textColor : '#120148'}}
                            type="text"
                            id="fullName"
                            placeholder='שם מלא'
                            onChange={(e) => setFormData({...formData, fullName: e.target.value, })}
                            value={formData?.fullName}
                            required
                        />
                    </div>
                    <div className="form-item">
                        <input
                            style={{color: textColor ? textColor : '#120148'}}
                            type="text"
                            placeholder='מספר נייד'
                            id="cellphone"
                            onChange={(e) => setFormData({...formData, cellphone: e.target.value})}
                            value={formData?.cellphone}
                            required
                        />
                    </div>
                    <div className="form-item">
                        <input
                            style={{color: textColor ? textColor : '#120148'}}
                            type="email"
                            placeholder='כתובת מייל'
                            id="email"
                            onChange={(e) => setFormData({...formData, email: e.target.value})}
                            value={formData?.email}
                        />
                    </div> 
                    <button className='continue_btn' onClick={handleSubmit}>{btnText}</button>
                </form>
                
                
            </div>
            
        </Fragment>
        
    )
}

export default ContactSection