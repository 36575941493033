import Home from './components/Home/Home';
import Layout from './components/Layout/Layout';
import { Routes, Route } from 'react-router-dom';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import { FormProvider } from './context/FormProvider';
import OurServices from './components/OurServices/OurServices';
import NotFound from './components/NotFound/NotFound';


const App = () => {
  return (
      <Routes>
        <Route element={<Layout />}>
          {/* public routes */}
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<FormProvider><Contact /></FormProvider>} />
          <Route path="/services" element={<OurServices />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
  );
}

export default App;