import { Fragment } from 'react';
import './NotFound.scss';

const image404 = require('../../assets/error_image.png');

const NotFound = () => {
    
    return (
        <Fragment>
            <h1>משהו לא טוב קרה :(</h1>
            <img alt='error_image' className='error_image' src={image404}/>
        </Fragment>
            
        
    )
}

export default NotFound;