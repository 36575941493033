import { useNavigate } from "react-router-dom";
import './Section.scss';

const Section = ({title, container, src, content, sectionType}) => {
    const navigate = useNavigate();
    return (
        <div className={container.className}>
            <img className={src.className} alt='curr_section_img' src={src.content}/>

            <div className='content_container'>
                <div className={title.className}>
                    {title.data}
                </div>  
                <div className={content.className}>
                    {content.data}
                </div>
                {sectionType === 'third' && <button className='dark_blue_btn' onClick={e => navigate('/services')}>
                    {`המשיכו לקרוא >>`}
                </button>}
            </div>
            

        </div>
    )
}

export default Section