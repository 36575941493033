import './Footer.scss';
import { useNavigate } from "react-router-dom";
import ContactSection from '../Sections/ContactSection/ContactSection';

const footerLogo = require('../../assets/logo_footer.png');

const mailLogo = require('../../assets/mail_footer.svg');
const cellphoneLogo = require('../../assets/phone_footer.svg');
const facebookLogo = require('../../assets/facebook_footer.svg');
const instagramLogo = require('../../assets/instagram_footer.svg');
const whatsappLogo = require('../../assets/whatsapp_footer.svg');


const Footer = () => {
    const navigate = useNavigate();
    
    return (
        <div className="footer">
            <div className='contact_us'>
                <img alt='bright_logo' className='bright_logo' src={footerLogo}/>
                <div className='media_container'>
                    <div className='item'>
                        <a href="https://api.whatsapp.com/send?phone=0542096095" target="_blank" rel="noreferrer">
                            <img alt='media_img' className='media_img' src={whatsappLogo.default}/>
                        </a>
                        <div className='media_text'>לחצו לשליחת הודעה</div>
                    </div>
                    <div className='item'>
                        <a href="tel:0542096095" target="_blank" rel="noreferrer">
                            <img alt='media_img' className='media_img' src={cellphoneLogo.default}/>
                        </a>
                        <div className='media_text'>054-209-6095</div>
                    </div>
                    <div className='item'>
                        <a href="mailto:yossil@wappy.co.il" target="_blank" rel="noreferrer">
                            <img alt='media_img' className='media_img' src={mailLogo.default}/>
                        </a>
                        <div className='media_text'>yossil@wappy.co.il</div>
                    </div>
                    <div className='item'>
                        <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                            <img alt='media_img' className='media_img' src={instagramLogo.default}/>
                        </a>
                        <div className='media_text'></div>
                    </div>
                    <div className='item'>
                        <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                            <img alt='media_img' className='media_img' src={facebookLogo.default}/>
                        </a>
                        <div className='media_text'></div>
                    </div>
                </div>
            </div>
            <div className='navigation'>
                <div className='nav_title'>
                    ניווט מהיר
                </div>
                <div className='nav_routes'>
                    <div className='route' onClick={e => navigate('/')}>דף בית</div>
                    <div className='route' onClick={e => navigate('/about')}>אודות</div>
                    <div className='route' onClick={e => navigate('/services')}>שירותים</div>
                    <div className='route' onClick={e => navigate('/contact_us')}>יצירת קשר</div>
                    <div className='route' onClick={e => navigate('/regulations')}>תקנון האתר</div>
                </div>
            </div>
            <div className='form_submit'>
                <ContactSection
                    title={'בואו נדבר!'}
                    btnText={'תחזור אלי'}
                    className={'contact_section_container_footer'}
                    isSectionImg={false}
                    text={'השאירו פרטים ואחזור אליכם בהקדם'}
                    textColor={'white'}
                ></ContactSection>
            </div>
            <div className='rights_deserved'>
                <div className='rights_text'>
                    2023 © כל הזכויות שמורות ל- Wappy | עוצב ע"י סטודיו Marca ונבנה ע"י Wappy
                </div>
            </div>
        </div>
            
        
    )
}

export default Footer