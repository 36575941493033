import Section from '../../UI/Section/Section';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import './Home.scss';
import ServicesSection from '../Sections/ServicesSection/ServicesSection';
import ProjectsSection from '../Sections/ProjectSection/ProjectsSection';
import ContactSection from '../Sections/ContactSection/ContactSection';
import Footer from '../Footer/Footer';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { FormProvider } from '../../context/FormProvider';

const firstSectionBg = require('../../assets/first_section_bg.png');
const firstSectionBot = require('../../assets/first_section_bot.png');
const firstSectionArrow = require('../../assets/first_section_arrow.png');
const firstSectionArrowHover = require('../../assets/first_section_arrow_hover.png');

const secondSectionImage = require('../../assets/second_section_image.png');
const secondSectionLogos = require('../../assets/second_section_logos.png');
const secondSectionLogosMobile = require('../../assets/second_section_logos_mobile.png');

const thirdSectionImage = require('../../assets/third_section_image.png');
const thirdSectionArrow = require('../../assets/blue-v.png');
const thirdSectionLine = require('../../assets/blue-line.png');

const fourthSectionImage = require('../../assets/fourth_section_image.png');
const fourthSectionArrow = require('../../assets/torq-v.png');

const contactImage = require('../../assets/contact_section_image.png');

const firstSection = {
    title: {data: 'העסק שלך עדיין לא עובד בצורה חכמה?', className: 'section_title'},
    container: { className: 'section_container'},
    src: {content: firstSectionBot, className: 'section_src robot'},
    content: {className: 'section_content font-danidin-regular', data: `בניית מערכות CRM לכל עסק ולכל כיס, דגש על חווית המשתמש בעזרת התאמה מלאה ללקוח ע"י תהליך ייחודי ובשימוש הטכנולוגיות הכי חדישות היום בעולם ההייטק.`}
}

const secondSection = {
    title: {data: 'Wappy הוקמה כדי לאפשר לעסקים לנהל את המשאבים שלהם', className: 'section_title'},
    container: { className: 'section_container'},
    src: {content: secondSectionImage, className: 'section_src'},
    content: {className: 'section_content', data: `עובדים, טפסים, חשבוניות, יומנים, חנות דיגיטלית, שימוש
    בפלטפורמות שונות ועוד - בצורה חכמה, יעילה ונוחה ע"י מתן אפיון
    מעמיק, דאגה מלאה לחוויית המשתמש ובניה מודולרית, באמצעות
    שיתוף הלקוח בתהליך ויצירת פיצ'רים יעודיים.`}
}

const thirdSection = {
        title: {data: 'בניית מערכת CRM כרוכה במספר תהליכים', className: 'section_title'},
        container: { className: 'section_container'},
        src: {content: thirdSectionImage, className: 'section_src'},
        content: {className: 'section_content', 
        data: <Fragment>
                <div className='top_text'>
                    <div className='list_item'>
                        <img className='v_img' alt='v_img' src={thirdSectionArrow}/>
                        <p>הבנת צרכי הלקוח, איך התמודד איתם עד כה ומה אפשר לשנות.<br/>
                            האם יש צורך בצד אנליטי, צד פונקציונאלי, או אולי פתרון כולל שיכיל את<br/>
                            הנ"ל בנוסף לצד תקשורתי (ניהול יומנים).</p>
                    </div>
                    <div className='list_item'>
                        <img className='v_img' alt='v_img' src={thirdSectionArrow}/>
                        <p>חדשנות, שינוי ושדרוג קונספטים קיימים מתוך הבנת הלוגיקה העסקית.</p>
                    </div>
                    <div className='list_item'>
                        <img className='v_img' alt='v_img' src={thirdSectionArrow}/>
                        <p>להבין ולהציב מטרות נוספות לפיתוח המערכת בכדי לקדם את העסק.</p>
                    </div>
                </div>
                <p className='mid_text'>
                כאשר הגדרנו את צרכי הלקוח, התאמנו את הפיצ'רים הרצויים<br/>
                והבנו את מטרות העתידיות, נוכל לצאת לדרך של בניה מותאמת אישית.
                </p>
                <img className='blue_line' alt='blue_line' src={thirdSectionLine}/>
                <p className='bottom_text'>
                כאשר בWAPPY אנו מציעים התאמה מלאה של בחירת הפיצ'רים הרלוונטים לך.
                אנו מאמינים שלא כל עסק קטן או גדול ככל שיהיה יש צורך בכל הפיצ'רים הנ"ל,
                לפעמים המערכת היא לשימוש פנימי, לפעמים היא לשימוש בין העובדים ללקוחות
                ולפעמים יש צורך בפתרון כולל.
                </p>
            </Fragment>

    }
}

const fourthSection = {
    title: {data: `בנוסף למערכות CRM אנו מציעים בניה של אתרי תוכן, אתרי איקומרס ואפליקציות`, className: 'section_title'},
    container: { className: 'section_container'},
    src: {content: fourthSectionImage, className: 'section_src robot'},
    content: {className: 'section_content', 
    data: <Fragment>
            <div className='top_text'>
                כלל השירותים מתבצעים עם הטכנולוגיות החדישות ביותר כאשר הדגש הוא על חווית המשתמש.
            </div>
            <div className='bottom_text'>
                <div className='list_item'>
                    <img className='v_img' alt='v_img' src={fourthSectionArrow}/>
                    <p>אתרי תוכן עם זמני טעינה הכי מהירים בשוק עם התאמה מלאה של אנימציות ואפקטים.</p>
                </div>
                <div className='list_item'>
                    <img className='v_img' alt='v_img' src={fourthSectionArrow}/>
                    <p>אתרי איקומרס עם נוחות המשתמש להעלאת מידע</p>
                </div>
                <div className='list_item'>
                    <img className='v_img' alt='v_img' src={fourthSectionArrow}/>
                    <p>אפליקציות מותאמות גם לAndroid וגם לIOS.</p>
                </div>
                <div className='list_item'>
                    <img className='v_img' alt='v_img' src={fourthSectionArrow}/>
                    <p>העיצוב הוא בסטנדרט הכי גבוה שקיים בכדי לספק חווית משתמש מקסימלית.</p>
                </div>
            </div>
        </Fragment>

    }
}


const Home = () => {
    const [hover, setHover] = useState(false);
    const { width } = useWindowDimensions();
    const secondSectionRef = useRef();
    
    const botAnimation = useCallback(() => {
        const elems = Array.from(document.getElementsByClassName('robot'));
        elems.forEach( elem => {
            console.log(elem)
        })

    }, [])

    const moveNext = useCallback(() => {
        secondSectionRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
    },[])

    useEffect(() => {
    }, [botAnimation])


    return (
        <div className="home">
            <div className='first_section home_section'>
                <img alt='first_section_bg' className='first_section_bg' src={firstSectionBg}/>
                <img onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)} 
                    alt='first_section_arrow' 
                    className='first_section_arrow' 
                    src={hover ? firstSectionArrowHover : firstSectionArrow}
                    onClick={() => moveNext()}
                />
                <Section
                    title={firstSection.title}
                    container={firstSection.container}
                    src={firstSection.src}
                    content={firstSection.content}
                >
                </Section>
            </div>
            <div className='second_section home_section' id='second_section' ref={secondSectionRef}>
                <Section
                    title={secondSection.title}
                    container={secondSection.container}
                    src={secondSection.src}
                    content={secondSection.content}
                >
                </Section>
            </div>
            { width > 661 ?
                <img src={secondSectionLogos} alt="second_section_logos" className='second_section_logos'/>
                :
                <img src={secondSectionLogosMobile} alt="second_section_logos" className='second_section_logos'/>
            }
            <div className='third_section home_section'>
                <Section
                    title={thirdSection.title}
                    container={thirdSection.container}
                    src={thirdSection.src}
                    content={thirdSection.content}
                    sectionType={'third'}
                >
                </Section>
            </div>
            <div className='fourth_section home_section'>
                <Section
                    title={fourthSection.title}
                    container={fourthSection.container}
                    src={fourthSection.src}
                    content={fourthSection.content}
                >
                </Section>
            </div>
            <div className='random_section service_section home_section'>
                <ServicesSection
                    btnText={'המשיכו לקרוא >>'}
                ></ServicesSection>
            </div>
            <div className='random_section project_section home_section'>
                <ProjectsSection 
                    title={'פרוייקטים אחרונים'}
                    btnText={'לכל העבודות >>'}
                ></ProjectsSection>
            </div>
            <FormProvider>
                <div className='random_section sixth_section home_section'>
                    <ContactSection
                        title={'אז איך אוכל לעזור לך?'}
                        btnText={'שליחה'}
                        className={'contact_section_container'}
                        isSectionImg={true}
                        src={contactImage}
                        text={'השאירו פרטים ואחזור אליכם בהקדם האפשרי!'}
                    ></ContactSection>
                </div>
            </FormProvider>
            <FormProvider>
                <div className='random_section footer_section'>
                    <Footer></Footer>
                </div>
            </FormProvider>
        </div>
    )
}

export default Home