import './OurServices.scss';
import { FormProvider } from '../../context/FormProvider';
import ContactSection from '../Sections/ContactSection/ContactSection';
import Footer from '../Footer/Footer';
import CustomerSection from '../Sections/CustomerSection/CustomerSection';

const serviceDude = require('../../assets/services_dude.png');

const torqV = require('../../assets/torq-v.png');

const contactImage = require('../../assets/contact_section_image.png');

const blueLineImg = require('../../assets/blue-line.png');

const servicesRobot = require('../../assets/services_robot.png');

const crm_basic = [
    'מערכת משתמשים', 
    'יומן', 
    'תזכורות / נוטיפיקציות', 
    'הרשאות גישה', 
    'אנליטיקה',
    'שיתוף קבצים',
    'ניהול משימות'
]

const crm_advanced = [
    'שיווק במייל',
    'אינטראקציה וניהול המידע בינך לבין הלקוחות',
    'מעקב אחר משימות',
    'התאמה למובייל',
    'חשבוניות',
    'אינטגרציה עם מערכות שונות',
    "צ'טבוט",
    'מספור לידים(תיעדוף)',
    'אינטגרציה עם הרשתות החברתיות'
]

const OurServices = () => {
    
    return (
        <div className="our_services">
            <div className='services_first_section'>
                <div className='first_section_title'>
                    בWAPPY תקבלו יחס אישי
                </div>
                <div className='first_section_secondary_title'>
                    הלקוח יקבל חוויה מותאמת אישית על כל יצירת מוצר אשר ירצה, מהקטן עד לגדול.
                </div>
                <div className='first_section_third_title'>
                    <p>
                        אז איך אנחנו עושים את זה?
                    </p>
                    <img alt='line' className='line' src={blueLineImg} />
                </div>
                <div className='first_section_container'>
                    <div className='characteristic'>
                        <div className='main_title'>
                            מתחילים באיפיון
                        </div>
                        <p className='main_paragraph'>
                        המטרה שלנו היא לחוות את המוצר,<br/>
                        ביחד נחליט אילו פיצ'רים יהיו, למה העסק זקוק ונגדיר את אופי היישום לפרטי פרטים.
                        </p>
                        <div className='first_meeting'>
                            <div className='meeting_title'>
                                פגישה ראשונה:
                            </div>
                            <p className='meeting_content'>
                                החלטה על פיצ'רים. לאחר שאתם תשתפו אותי בצרכי החברה, אני אוכל להתאים את המוצר בצורה טובה יותר<br/>
                                ובכך להחליט באופן יותר יעיל אילו פיצ'רים יהיו יותר פרקטיים לכם, איך כדאי להתקדם מבחינת פיתוח עתידי במידת הצורך ועל אילו פיצ'רים כדאי<br/>
                                לוותר במידת הצורך בהתאם לתקציב.<br/><br/>

                                לאחר הפגישה הראשונית תישלח לכם הצעת מחיר וחוזה.
                            </p>
                        </div>
                        <div className='second_meeting'>
                            <div className='meeting_title'>
                                פגישה שניה:
                            </div>
                            <p className='meeting_content'>
                                אפיון עמודים, כל עמוד ישורטט על כל מאפייניו.<br/>
                                בשלב זה מדובר רק על הפיצ'רים שהוחלטו.<br/>
                                נבנה סקיצה ראשונית של כל עמוד בלייב, נדון על כך ונוביל את המוצר לפיתוח.<br/>
                            </p>
                        </div>
                        {/* <div className='third_meeting'>
                            <div className='meeting_title'>
                                פגישה שלישית:
                            </div>
                            <p className='meeting_content'>
                                אישור העיצוב מצד הלקוח, בתוספת של שינויים ותיקונים.<br/>
                                יש אפשרות לספק עיצוב מהצד שלכם ולכן זה מצטמצם עם העלות במידה וכן.
                            </p>
                        </div> */}
                        {/* <div className='fourth_meeting'>
                            <div className='meeting_title'>
                                פגישה רביעית:
                            </div>
                            <p className='meeting_content'>
                                באמצע הדרך של התאריך המשוער, כדי לאפשר לכם לראות את<br/>
                                ההתקדמות והנכונות שלנו להצלחה מירבית.   
                            </p>
                        
                        </div> */}
                        <div className='fifth_meeting'>
                            <div className='meeting_title'>
                                פגישה שלישית:
                            </div>
                            <p className='meeting_content'>
                                לקראת סיום, אנו נעבור על כל חלק קטן וגדול של המוצר ונראה שיש
                                התאמה מלאה למה שציפיתם לקבל. <br/>
                                נעביר כ2 מקצי תיקונים של כשבוע כל אחד, עד לשביעות רצון מלאה.
                            </p>
                        </div>
                    </div>
                    <img className='contact_section_dude' alt='contact_section' src={serviceDude}/>
                </div>
                
            </div>
            <div className='services_second_section'>
                <div className='second_section_title'>
                    מערכות CRM
                </div>
                <div className='second_section_content'>
                    <div className='crm_characteristics'>
                        <div className='basic_crm'>
                            <div className='crm_title'>
                                למערכות CRM יש כ7- קונספטים בסיסיים וייחודיים:
                            </div>
                            {crm_basic.map((crm_item, i) => {
                                return <div className='crm_item' key={'crm_basic_item' + i}>
                                    <img className='torqoise_v' alt='torqoise_v' src={torqV}/>
                                    <p className='crm_item_content'>
                                        {crm_item}  
                                    </p>
                                </div>
                            })}
                            
                        </div>
                        <div className='advanced_crm'>
                            <div className='crm_title'>
                                בנוסף לכ9- קונספטים מתקדמים:
                            </div>
                            {crm_advanced.map((crm_item, i) => {
                                return <div className='crm_item' key={'crm_advanced_item' + i}>
                                    <img className='torqoise_v' alt='torqoise_v' src={torqV}/>
                                    <p className='crm_item_content'>
                                        {crm_item}  
                                    </p>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className='bottom_paragraph'>
                        כמוצר בסיס, השמיים הם הגבול. ההמלצה שלנו היא להתחיל עם מערכת משתמשים, ניהול משימות והתראות.<br/>
                        ביכולתכם לשכלל כל פיצ'ר למיטב, לדוגמא מערכת משתמשים יכולה לשמש גם כאבטחה וגם כהגבלת מידע.<br/><br/>
                        
                        לאורך השלבים המפורטים הנ"ל, נתקדם מפגישה לפגישה. ללקוח יש שקיפות מלאה לתהליך.<br/>
                    </div>
                </div>
            </div>
            <div className='services_third_section'>
                <div className='services_info_section'>
                    <div className='services_info_content'>
                        <div className='services_info_item'>
                            <div className='services_info_item_title'>
                            אתרי תוכן   
                            </div>
                            <p className='services_info_item_content'>
                                שלא כמו רוב החברות היום בשוק, אתר תוכן לא יכתב בוורדפרס אלא יכתב מ0 ויכלול את מיטב
                                היכולות ההתאמה האישיות כגון אנימציות ושליטה מלאה בתוכן. <br/>
                                ישנה גם אופציה ליצירת אתר עם טמפלייט בסיסי ופחות התאמה אשר נכתב בטכנולוגיית וורדפרס.
                            </p>
                        </div>
                        <div className='services_info_item'>
                            <div className='services_info_item_title'>
                                אתרי איקומרס
                            </div>
                            <p className='services_info_item_content'>
                            האתר יכתב בטכנולוגיית וורדפרס, יציע חנות דיגיטלית, הכנה לשיווק דיגיטלי, ניהול חשבונות,
                            תשלומים וניהול מוצרים.<br/>
                            במידה ותרצו יותר שליטה על האתר, האתר יכתב מ0 ויהיה יותר מותאם אישית.
                            </p>
                        </div>
                        <div className='services_info_item'>
                            <div className='services_info_item_title'>
                            אפליקציות
                            </div>
                            <p className='services_info_item_content'>
                            האפליקציות נכתבות בטכנולוגיית Native-React אשר מאפשרת ליצור מוצר מאוזן גם
                            לאנדרויד וגם לIOS. האפליקציות מאובטחות ונוצרות בסטנדרט של שוק ההייטק היום.
                            </p>
                        </div>
                    </div>
                    <img alt='services_info_robot' className='services_info_robot' src={servicesRobot}/>
                </div>
                <div className='bottom-paragraph'>
                המוצרים שיסופקו, יהיו בסטנדרט הכי גבוה שיש עם הטכנולוגיות הכי חדישות, התהליך יהיה מותאם אישית
                <br/>
                ולכן גם המחירים יהיו מותאמים אישית. כל המוצרים ילוו בעיצוב מקצועי, ואחזקת שרתים.
                <br/>
                כל עבודה תתומחר עם ריטיינר חודשי לפי כמות המשתמשים / עבודת התחזוקה.<br/>
                </div>
            </div>
            <div className='customer_section'>
                <CustomerSection></CustomerSection>
            </div>
            <FormProvider>
                <div className='random_section sixth_section home_section'>
                    <ContactSection
                        title={'אז איך אוכל לעזור לך?'}
                        btnText={'שליחה'}
                        className={'contact_section_container'}
                        isSectionImg={true}
                        src={contactImage}
                        text={'השאירו פרטים ואחזור אליכם בהקדם האפשרי!'}
                    ></ContactSection>
                </div>
            </FormProvider>
            <FormProvider>
                <div className='random_section footer_section'>
                    <Footer></Footer>
                </div>
            </FormProvider>
        </div>
    )
}

export default OurServices