import { FormProvider } from '../../context/FormProvider';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Footer from '../Footer/Footer';
import ContactSection from '../Sections/ContactSection/ContactSection';
import CustomerSection from '../Sections/CustomerSection/CustomerSection';
import ServicesSection from '../Sections/ServicesSection/ServicesSection';
import './About.scss';

const contactSectionBot = require('../../assets/first_section_bot.png');

const secondSectionLogos = require('../../assets/second_section_logos.png');
const secondSectionLogosMobile = require('../../assets/second_section_logos_mobile.png');

const aboutRobot = require('../../assets/gearbot.png');

const meCropped = require('../../assets/me_cropped.jpeg');

const About = () => {
    const { width } = useWindowDimensions();

    return (
        <div className='about'>
            <div className='first_section'>
                <div className='nice_to_meet'>
                    <div className='ntm_content'>
                        <div className='ntm_title'>
                        היי, נעים להכיר
                        </div>
                        <p className='ntm_info'>
                        שמי יוסי לופטון, מפתח תוכנה עם תשוקה לתכנות, אהבה למשחקי מחשב וטניס.<br/>
                        במקור מחיפה, בעל תואר מאוניברסיטת אריאל, BSC במדעי המחשב, וניסיון מעשי בתעשיה.<br/>
                        בעל צמא אינסופי לידע, דחף ללמוד ולספק מוצר על הצד הטוב ביותר.<br/>
                        אוהב אתגרים בתחום התכנות עם מוטיבציה גבוהה אשר יבואו לידי ביטוי בתהליך שנעשה ביחד.
                        {/* אוהב את האתגר והיצירה של משהו חדש מאפס, אוהב לחשוב לבד ועם הלקוח<br/>
                        על הביצוע ולאחר מכן נהנה מהביצוע בהתאם.<br/> */}
                        </p>
                        <p className='highlighted_info'>כל מה שצריך זה 2 כלבים, תשוקה, רעיונות טובים ומחשב.</p>
                    </div>
                    <div className='profile'>
                        <img className='profile_img' alt='profile_frame' src={meCropped}/>
                    </div>
                </div>
                <div className='wappy_content_container'>
                    <img className='robot' alt='robot' src={aboutRobot}/>
                    <div className='wappy_data'>
                        <div className='wappy_title'>
                            WAPPY היא חברת פרוייקטים
                        </div>
                        <div className='wappy_info'>
                            Wappy היא תוצר של ויז'ן משותף.<br/>
                            אנו בונים אתרים ומערכות יעילות ללקוחותינו.<br/>
                            המוטיבציה שלנו היא לפתח מערכות ואתרים שייצרו תוצאות ויספקו החזר בהתאם להשקעה.
                            תוך כדי יחס אישי והתאמה מלאה לאורך כל הדרך במחירים נוחים.<br/>
                            <br/>
                            מטרתנו הסופית היא לספק מוצר בעל איכות גבוהה עם עיצוב ממוקד למשתמש וטכנולוגיה חדישה.
                            {/* אנו בונים אתרים ומערכות יעילות ללקוחותינו.<br/>
                            המוטיבציה שלנו היא לפתח מוצר אשר לאורך זמן יקל עלייך, יספק תוצאות בזמן אמת ויחזיר<br/>
                            השקעות, תוך כדי יחס אישי והתאמה מלאה לאורך כל הדרך. */}
                        </div>
                    </div>
                    
                </div>
                
            </div>
            { width > 661 ?
                <img src={secondSectionLogos} alt="section_logos" className='section_logos'/>
                :
                <img src={secondSectionLogosMobile} alt="section_logos" className='section_logos'/>
            }
            <div className='customer_section'>
                <CustomerSection></CustomerSection>
            </div>
            <div className='service_section'>
                <ServicesSection
                    title={'מה אנחנו עושים'}
                    btnText={'מעבר לדף שירותים >>'}
                ></ServicesSection>
            </div>
            <FormProvider>
                <div className='about_contact_section random_section sixth_section home_section'>
                    <ContactSection
                        title={'אז איך אוכל לעזור לך?'}
                        btnText={'שליחה'}
                        className={'contact_section_container'}
                        isSectionImg={true}
                        src={contactSectionBot}
                        text={'השאירו פרטים ואחזור אליכם בהקדם האפשרי!'}
                    ></ContactSection>
                </div>
                <div className='footer_section'>
                    <Footer></Footer>
                </div>
            </FormProvider>
        </div>
    )
}

export default About