import { createContext, useState, useEffect, useMemo, useCallback } from "react";
import axios from 'axios';

const FormContext = createContext({});

const DB_URL = 'https://wappy-de5bb-default-rtdb.europe-west1.firebasedatabase.app/leads.json';

const defaultForm = {
    fullName: '',
    cellphone: '',
    email: '',
    freeText: ''
}

export const FormProvider = ({ children }) => {
    const [formData, setFormData] = useState(defaultForm);

    const [errMsg, setErrMsg] = useState('');
    
    const handleSubmit = useCallback(async (e) => {
        console.log(formData)
        e.preventDefault();
        try {
            const response = await axios.post(DB_URL,
                formData,
            );
            
            if(response.data){
                setFormData(defaultForm);
            }
        } catch (err) {
            console.log(err.response)
            if (!err?.response.data.message) {
                setErrMsg('No Server Response');
                alert(err.response.data.message)
            } else {
                setErrMsg('העלאת דירה נכשלה');
            }
        }
    },[formData])

    

    useEffect(() =>{
    },[handleSubmit, formData]);
    

    const contextValue = useMemo(() => ({
        formData,
        setFormData,
        handleSubmit,
        errMsg, 
        setErrMsg
      }), [formData, setFormData, handleSubmit, errMsg, setErrMsg])
    
    return (
        <FormContext.Provider value={contextValue}>
            {children}
        </FormContext.Provider>
    )
}

export default FormContext;