import { useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from "react-icons/fa";
import './Header.scss';
import { useRef } from 'react';

const headerLogo = require('../../assets/header_logo.png');
const whatsappLogo = require('../../assets/whatsapp_header.svg');
const mobileLogo = require('../../assets/mobile_header.svg');

const pages = [
    {page: 'צור קשר', link: 'contact'},
    // {page: 'פורטפוליו', link: 'portfolio'},
    {page: 'שירותים', link: 'services'},
    {page: 'אודות', link: 'about'},
    {page: 'דף הבית', link: '/'},
];

const Header = () => {
    const navRef = useRef();
    const navigate = useNavigate();

    const showNavbar = () => {
		navRef.current.classList.toggle("responsive_nav");
	};

    return (
        <div className="header">
            <img className='header_logo' alt='wappy_logo' src={headerLogo}/>
            <nav className='pages_container' ref={navRef}>
                {pages.map((page, i) => {
                    return <h3 key={'page_link' + i} className='page_link' onClick={e => {
                        navigate(page.link);
                        showNavbar();
                        }}>
                        {page.page}
                    </h3>
                })}
                <button
                    className="nav-btn nav-close-btn"
                    onClick={showNavbar}>
                    <FaTimes />
                </button>
            </nav>
            <button className="nav-btn" onClick={showNavbar}>
              <FaBars />
            </button>
            <div className='contact_container'>
                <a href="https://api.whatsapp.com/send?phone=0542096095" target="_blank" rel="noreferrer">
                    <img className='contact_logo' alt='whatsapp_logo' src={whatsappLogo.default}/>
                </a>
                <a href="tel:0542096095" target="_blank" rel="noreferrer">
                    <img className='contact_logo' alt='mobile_logo' src={mobileLogo.default}/>
                </a>
            </div>
        </div>
    )
}

export default Header