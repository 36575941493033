import './Contact.scss';
import Footer from '../Footer/Footer';
import useForm from '../../hooks/useForm';

const contactPerson = require('../../assets/contact_person.png');
const cellphoneLogo = require('../../assets/phone_contact.png');
const facebookLogo = require('../../assets/facebook_contact.png');
const mailLogo = require('../../assets/mail_contact.png');
const whatsappLogo = require('../../assets/whatsapp_contact.png');
const linkdinLogo = require('../../assets/linkdin_contact.png');

const Contact = () => {
    const { formData, setFormData, handleSubmit } = useForm();

    return (
        <div className="contact">
            <div className='main_contact_section'>
                <div className='contact_media'>
                    <div className='media_title'>
                        בואו נדבר
                    </div>
                    <div className='media_container'>
                        <div className='item'>
                            <a href="https://api.whatsapp.com/send?phone=0542096095" target="_blank" rel="noreferrer">
                                <img alt='media_img' className='media_img' src={whatsappLogo}/>
                            </a>
                            <div className='media_text'>לחצו לשליחת הודעה</div>
                        </div>
                        <div className='item'>
                            <a href="tel:0542096095" target="_blank" rel="noreferrer">
                                <img alt='media_img' className='media_img' src={cellphoneLogo}/>
                            </a>
                            <div className='media_text'>054-209-6095</div>
                        </div>
                        <div className='item'>
                            <a href="mailto:yossil@wappy.co.il" target="_blank" rel="noreferrer">
                                <img alt='media_img' className='media_img' src={mailLogo}/>
                            </a>
                            <div className='media_text'>yossil@wappy.co.il</div>
                        </div>
                        <div className='item'>
                            <a href="https://www.linkedin.com/in/yossi-loufton/" target="_blank" rel="noreferrer">
                                <img alt='media_img' className='media_img' src={linkdinLogo}/>
                            </a>
                            <div className='media_text'>https://www.linkedin.com/in/yossi-loufton</div>
                        </div>
                        <div className='item'>
                            <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                                <img alt='media_img' className='media_img' src={facebookLogo}/>
                            </a>
                            <div className='media_text'></div>
                        </div>
                    </div>
                </div>
                <img alt='contact_person_img' className='contact_person_img' src={contactPerson}/>
                <div className='main_form'>
                    <div className='form_main_title'>
                        מעוניינים במידע נוסף?
                    </div>
                    <div className='form_secondary_title'>
                        השאירו פרטים ואחזור אליכם בהקדם
                    </div>
                    <form className="contact_form">
                        <div className="form-item">
                            <input
                                type="text"
                                id="fullName"
                                placeholder='שם מלא'
                                onChange={(e) => setFormData({...formData, fullName: e.target.value, })}
                                value={formData?.fullName}
                                required
                            />
                        </div>
                        <div className="form-item">
                            <input
                                type="text"
                                placeholder='מספר נייד'
                                id="cellphone"
                                onChange={(e) => setFormData({...formData, cellphone: e.target.value})}
                                value={formData?.cellphone}
                                required
                            />
                        </div>
                        <div className="form-item">
                            <input
                                type="email"
                                placeholder='כתובת מייל'
                                id="email"
                                onChange={(e) => setFormData({...formData, email: e.target.value})}
                                value={formData?.email}
                            />
                        </div> 
                        <div className="form-item">
                            <textarea
                                type="text"
                                placeholder='כתבו הודעה כאן...'
                                id="text"
                                onChange={(e) => setFormData({...formData, freeText: e.target.value})}
                                value={formData?.freeText}
                            />
                        </div> 
                        <button className='continue_btn' onClick={handleSubmit}>שליחה</button>
                    </form>
                </div>
            </div>
            <div className='footer_section'>
                <Footer></Footer>
            </div>
        </div>
            
        
    )
}

export default Contact