import { Fragment, useState } from 'react';
import './CustomerSection.scss';

const rightArrowIcon = require('../../../assets/right_arrow.png');
const leftArrowIcon = require('../../../assets/left_arrow.png');

const spartaLogo = require('../../../assets/sparta_logo.jpg');
const nofarLogo = 'https://sparta-living.s3.eu-west-1.amazonaws.com/wappy/nofar-logo.png';

const container = [
    {src: nofarLogo, title: 'Nofar Interior Design', text: 'לאחרונה עבדתי עם יוסי מ-Wappy על אתר חדש לחברה שלנו והחוויה הייתה מדהימה. יוסי הפך את הרעיונות שלנו למציאות בצורה מרשימה, עם דף בית, אודות, פרויקטים ויצירת קשר. הוא מאוד מקצועי, יצירתי וקשוב. אם אתם מחפשים מישהו שיביא את האתר שלכם לחיים, יוסי זה הכתובת.'},
    {src: spartaLogo, title: 'Sparta Living', text: '"WAPPY סיפקו לנו מענה והקימו למשרדנו מאגר הכולל בתוכו את כלל הדירות הקיימות במשרדינו ופרטיהן. סופקה לנו מערכת ידידותית למשתמש המושכת את העין, וכוללת את כלל הפרטים אשר ביקשנו, ועונה על כל צרכינו. ראוי לציין כי קיבלנו מ Wappy שירות שאין כמותו ובזמינות גבוהה. רצינו להגיד תודה רבה ל Wappy וליוסי על השירות וההיענות, אנחנו ממליצים בחום."'},
]

const CustomerSection = ({btnText, title}) => {
    const [cursor, setCursor] = useState(1);

    const onArrowClick = (direction) => {
        if(direction === 'left'){
            if(cursor === 0){
                setCursor(container.length - 1)
            } else {
                setCursor(oldValue => Math.abs((oldValue - 1) % (container.length)));
            }
            
        } else {
            setCursor(oldValue => Math.abs((oldValue + 1) % container.length));
        }
    }

    return (
        <div className="pagination">
            <div className='pagination-title'>
                מה מספרים אלו שעבדו איתנו
            </div>
            <div className="pagination-content">
                <div className="carousel">
                    <div className="slider-wrapper">
                        <ul className="slider">
                            {container.map((item, i) => {
                                let indexLeft;
                                if(cursor === 0){
                                    indexLeft = container.length - 1
                                } else {
                                    indexLeft = Math.abs((cursor - 1) % container.length);
                                }
                                const indexRight = Math.abs((cursor + 1) % container.length);

                                let className = 'card';

                                if (i === cursor) {
                                    className = "card--active main_customer";
                                    } 
                                    else if (i === indexRight) {
                                    className = "right_customer";
                                    } else if (i === indexLeft) {
                                    className = "left_customer";
                                    } 
                                    else {
                                        className="no-card"
                                    }
                                    
                                if(container.length === 1) {
                                    className = "card--active main_customer";
                                }
                                return <Fragment key={i + 'slide_container'}>
                                            <li className={`${className} slide`} id={'customer' + i}  key={i + 'slide_team'}>
                                                
                                                <div className='customer_img_container'>
                                                    <img className="customer_img" alt="" src={item.src}/>
                                                </div>
                                                <div className="customer_data">
                                                    <div className="customer_name">
                                                        {item.title}
                                                    </div>
                                                    <div className="customer_text">
                                                        {item.text}
                                                    </div>
                                                </div>
                                            </li>
                                        </Fragment>
                                    
                                    
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            
            <div className="pagination-actions">
                <div className="pagination-arrows">
                    <img onClick={e => onArrowClick('left')} className="left-arrow" src={leftArrowIcon} alt=""/>
                    <img onClick={e => onArrowClick('right')} className="right-arrow" src={rightArrowIcon} alt=""/>
                </div>
            </div>
        </div>
        
    )
}

export default CustomerSection