import React, { StrictMode }  from 'react';
import './index.scss';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './fonts/DanidinRegular/Danidin-Regular.ttf';
import './fonts/FuturismBlack/Futurism-Black.ttf';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<StrictMode>
  <BrowserRouter>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
  </BrowserRouter>
</StrictMode>,);