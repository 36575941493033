import { useEffect, useState } from "react";
import "./imageSlider.scss";
// import { useLocation } from "react-router-dom";

const slideStyles = {
  width: "100%",
  height: "90%",
  borderRadius: "10px",
  backgroundSize: "contain",
  backgroundPosition: "center",
};

const rightArrowStyles = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  right: "10px",
  fontSize: "45px",
  color: "#e8f1f9",
  zIndex: 1,
  cursor: "pointer",
};

const leftArrowStyles = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  left: "10px",
  fontSize: "45px",
  color: "#e8f1f9",
  zIndex: 1,
  cursor: "pointer",
};

const sliderStyles = {
  position: "relative",
  height: "100%",
};

const dotsContainerStyles = {
  height: '10%',
  display: "flex",
  justifyContent: "center",
};

const dotStyle = {
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
};


const ImageSlider = ({ slides, openImg }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideStylesWidthBackground, setSlideStylesWidthBackground] = useState(
    {
      ...slideStyles,
      backgroundImage: `url(${slides[currentIndex]})`,
    }
  );
  // const location = useLocation()
  // const [isExternal, setIsExternal] = useState(location.pathname.includes('external'))


  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const oversizeImg = () => {
    openImg(slides[currentIndex])
  }

  useEffect(() => {
    setSlideStylesWidthBackground(
      {
        ...slideStyles,
        backgroundImage: `url(${slides[currentIndex]})`,
      }
    )
  },[setSlideStylesWidthBackground, currentIndex, slides])
  

  return (
    <div style={sliderStyles}>
        <div>
            <div style={dotsContainerStyles}>
                {slides.map((slide, slideIndex) => (
                    <div className="image-action-container" key={`image-${slideIndex}`}>
                        <div
                            style={dotStyle}
                            key={slideIndex}
                            onClick={() => goToSlide(slideIndex)}
                        >
                            ●
                        </div>
                    </div>
                ))}
            </div>
            <div onClick={goToPrevious} style={leftArrowStyles} className="arrow">
            ❱
            </div>
            <div onClick={goToNext} style={rightArrowStyles} className="arrow">
            ❰
            </div>
        </div>
      <div style={slideStylesWidthBackground} className="slide-background" onClick={oversizeImg}></div>
    </div>
  );
};

export default ImageSlider;